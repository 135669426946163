<template>
  <div class="body" :style="`bor background-size: cover`">
    <div class="login-div flex justify-center align-center">
      <div class="login-content">
        <div class="title">布朗英语</div>

        <el-form :model="formData" status-icon :rules="rules" ref="formData" label-width="0" class="demo-formData">
          <el-form-item label="" prop="phone">
            <el-input v-model="formData.phone" autocomplete="off" placeholder="请输入手机号">
              <i class="icon in-0 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input type="password" v-model="formData.password" autocomplete="off" placeholder="请输入密码">
              <i class="icon in-1 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="verifycode" style="margin-bottom: 5px">
            <el-row :gutter="25">
              <el-col :span="13">
                <el-input v-model="formData.verifycode" placeholder="请输入验证码">
                  <i class="icon in-2 el-input__icon" slot="prefix"> </i>
                </el-input>
              </el-col>
              <el-col :span="11">
                <div class="identifybox" @click="refreshCode">
                  <VerifyCode :identifyCode="identifyCode" :contentWidth="160"></VerifyCode>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <div class="remember_pwd"><input type="checkbox" v-model="checked" @change="storePwd()" class="remember_pwd_logo" /> 记住密码</div>

          <div class="button_login" @click="login" style="padding:0 10px">
            <span >登 录</span>
            </div>

          <div class="bottom">
            <div class="forgetpassword" @click="forgetpassword">忘记密码</div>
            <div class="register" @click="register">注册账号</div>
          </div>

          <div class="agreement">
            <div class="agreement1"><input type="checkbox" class="agreement_logo" v-model="isLogin" /> 已阅读协议</div>
            <div class="agreement2" @click="agreement">《用户协议》</div>
          </div>
        </el-form>
      </div>
      <img src="../../assets/img/login/login_bg_r.png" alt="" class="img_right" />

       <router-link to="/home">
        <div class="back">X</div>
         </router-link>
    </div>
    <div class="footer">
      {{ footLabel }}
    </div>
    <div class="cicleLeft"></div>
    <div class="cicleRight"></div>
  </div>
</template>

<script>
import VerifyCode from "@/components/VerifyCode";
import bodyBg from "../../assets/img/login/login-bg.png";

export default {
  components: {
    VerifyCode,
  },

  data() {
    return {
      checked:'',
      formData: {
        phone: "",
        password: "",
        verifycode: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: "请输入正确的号码格式", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入6-16位密码，区分大小写", trigger: "blur" },
          { pattern: /^[0-9A-Za-z]{6,16}$/, message: "请输入正确的密码格式", trigger: "blur" },
        ],
        verifycode: [{ validator: this.validateVerifycode, trigger: "blur" }],
      },
      isLogin: false,
      identifyCodes: "1234567890", //验证码的数字库
      identifyCode: "", // 验证码组件传值
      bodyBg: bodyBg,
      footLabel: "",
      timmer:'',
    };
  },

  created() {

    this.formData.phone=localStorage.getItem("bulangphone")
    this.formData.password=localStorage.getItem("bulangpwd")
    //刷新二维码
    this.refreshCode()
  },

  mounted(){},

  methods: {
    //记住密码
    storePwd(){
        console.log("this.checked",this.checked)
        if(this.checked==true){
              localStorage.setItem("bulangphone",this.formData.phone)
              localStorage.setItem("bulangpwd",this.formData.password)
        }
    },
    //忘记密码
    forgetpassword() {
      this.$router.push({
        path: "./forgetpassword",
      });
    },
    //注册
    register() {
      this.$router.push({
        path: "./register",
      });
    },
    //协议
    agreement() {
      this.$router.push({
        path: "./agreement",
      });
    },

    //验证码----start
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },

    validateVerifycode(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value !== this.identifyCode) {
        callback(new Error("验证码不正确!"));
      } else {
        callback();
      }
    },

    //验证码----end

    login() {
      //提交     
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.formData) {
            if (this.isLogin) {
              this.$api.login.login(this.formData).then((res) => {
                if (res.data.code === 200) {
                  sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
                  this.$router.push("/index");
                  //  this.sessionOutTime()
                }
              });
            } else {
              // this.$message.warning("请同意用户协议");
               this.$message({
                    message: "请同意用户协议",
                    type: "warning",
                    offset:'400'
                  });
            }
          }
        }
      });
    },

 //session两小时后失效
    // sessionOutTime(){
    //   this.timmer=setTimeout(()=>{   
    //     //清除session
    //     sessionStorage.removeItem("userInfo");
    //     sessionStorage.clear();
    //     //清除缓存
    //     // this.$cache.reset();
    //     //跳往登录页面
    //     this.$router.push({
    //       path: "/login",
    //   });
    //   },120*60*1000);//设置两个小时清空session进入登录页
    // },

    doLogin(){
      alert('doLogin');

    },

    resetForm() {
      //重置
      this.formData = {
        user: "",
        password: "",
        code: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  position: relative;
  height: 100vh;

  .more {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
   .back{
        position: absolute;
        font-weight: 550;
        height:5%;
        width:3%;
        // width: 5%;
        // height: 8%;
        line-height: 24.64px;
        border-radius: 5px;
       left: 90%;
       top: 7%;
          border: 1pt solid #f9be6f;
      // background:#f9be6f;
      color: #f9be6f;
      }
   .back:hover{
          border: none;
           background:rgb(232, 17, 35);
           color: #fff;
            line-height: 24.64px;
      }
    .back img{
      width: 100%;
      height: 100%;
    }
  .cicleLeft {
    background: #f9be6f;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    position: fixed;
    bottom: -200px;
    left: -120px;
    z-index: 1;
  }
  .cicleRight {
    background: #f9be6f;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    position: fixed;
    top: -150px;
    right: -150px;
    z-index: 1;
  }
  .login-div {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f7bd66;
    border-radius: 17px;
    margin: 0 auto;
    width: 929px;
    display: flex;
    height: 497px;
    background: #fff;
    .login-content {
      width: 385px;
      .title {
        font-size: 28px;
        text-align: left;
        margin-bottom: 33px;
        font-weight: bolder;
      }
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        margin: 8px 5px 0 0;
      }
     
      .in-0 {
        background: url("../../assets/img/login/user.png");
        background-size: cover;
      }
      .in-1 {
        background: url("../../assets/img/login/lock.png");
        background-size: cover;
      }
      .in-2 {
        background: url("../../assets/img/login/code.png");
        background-size: cover;
      }
      
      /deep/ .el-button--danger {
        background-color: #db3b3d;
        border-color: #db3b3d;
      }
      .code {
        width: 160px;
        height: 40px;
        background: url("../../assets/img/login/rCode.png");
        background-size: cover;
      }
      .btn {
        width: 100%;
        border-radius: 4px;
      }
    }
    .img_right {
      width: 375px;
      height: 154px;
      margin-left: 102px;
    }
  }
  .footer {
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 46px;
  }

  .remember_pwd {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 15px;
    .remember_pwd_logo {
      width: 18px;
      height: 19px;
      margin-right: 10px;
      border: 1px solid #eebf74;
    }
    .remember_pwd_logo:hover{
    cursor: pointer;
    }
  }
  .label-r {
    text-align: right;
    padding-top: 5px;
  }

  .button_login {
    width: 302px;
    height: 41px;
    margin-top: 15px;
    color: white;
    border-radius: 65px;
    line-height:41px;
    // background: -webkit-linear-gradient(left, rgba(243, 206, 136, 1), rgba(231, 134, 38, 1));
    background: #f9be6f;
    border: none;
  }
  .button_login:hover{
    cursor: pointer;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    .forgetpassword {
      color: rgba(231, 161, 38, 1);
      margin-left: 15px;
    }
    .forgetpassword:hover{
      cursor: pointer;
    }
    .register {
      margin-left: 240px;
      color: rgba(231, 161, 38, 1);
    }
    .register:hover{
      cursor: pointer;
    }
  }
  .agreement {
    display: flex;
    margin-top: 30px;
    align-items: center;
    .agreement2:hover{
      cursor: pointer;
    }
    .agreement1 {
      font-size: 14px;
      .agreement_logo {
        width: 19px;
        height: 19px;
        background-color: #ffffff;
        border: solid 1px #dddddd;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        vertical-align: top;
        cursor: default;
        -webkit-appearance: none;
        -webkit-user-select: none;
        user-select: none;
        -webkit-transition: background-color ease 0.1s;
        transition: background-color ease 0.1s;
      }
    }
     .agreement_logo:hover{
       cursor: pointer;
     }
    .agreement_logo:checked {
      background: #1673ff;
    }
    .agreement_logo:checked::after {
      content: "";
      top: 2px;
      left: 2px;
      position: absolute;
      background: transparent;
      border: #fff solid 2px;
      border-top: none;
      border-right: none;
      height: 6px;
      width: 10px;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .agreement2 {
      font-size: 14px;
      color: rgba(22, 137, 211, 1);
    }
  }

  /deep/ .el-input--prefix .el-input__inner {
    padding-left: 40px;
  }
}
</style>
